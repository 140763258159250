.menu {
	position: absolute;
	& .mainButton{
		width: 40px;
		height: 40px;
		border:#000 1px solid;
		border-radius: 5px;
		padding: 8px;
		background: #ddd;
		img{
			width: 100%;
			height: 100%;
		}
		&.open{
			box-shadow: #333 10px 20px 20px;
			border-bottom: 1px #ddd solid;
			border-radius: 5px 5px 0px 0px;
		

			&:after{
				content: "";
				display:block;
				left: 0px;
				right: 0px;
				bottom: -5px;
				z-index: 2501;

				position: absolute;
				background:#ddd;
				margin: 0 1px;
				height: 10px;
			}	
		}
	}
	& .items{
		box-shadow: #333 10px 20px 30px;
		z-index: 2500;
		position: absolute;
		background: #ddd;

		border: #000 1px solid;
		border-radius: 0 5px 5px 5px;
		padding: 5px;
		& button {
			height: 50px;
			padding: 5px 20px;
		}
		.item{
			margin-bottom: 10px;
			min-width: 200px;
		}
	}
	& .hidden{
		display:none;
	}
}

