
$notificationBG3: #fa0;



.warnings {
	display: flex;
	align-content: stretch;
	background:$notificationBG3;
	border: 1px solid #000;
	border-radius: 5px;
	padding: 5px 15px; 
	/*line-height: 30px;*/
	position: relative;
	z-index: 6000;
	width: 300px;
	vertical-align: middle;
}
.warnings .messageText {
	flex-grow: 100;
	padding:0 10px 0 20px;
	margin:auto;
}
@keyframes warningIcon{
	0%		{	transform: rotateY(0deg);}
	25%		{	transform: rotateY(0deg);}
	50%		{	transform: rotateY(360deg);}
	75%		{	transform: rotateY(0deg);}
	100%	{	transform: rotateY(0deg);}
}
.warnings .icon {
	flex-grow: 1;
	flex-shrink: 0;
	font-size: 30px;
	vertical-align: middle;
	display: inline;
	margin: auto;
	animation: warningIcon 5s 1;
	animation-iteration-count: infinite;
}
.warnings button {
	flex-grow: 1;
	flex-shrink: 0;
	margin-right:0;
}