$rhinoColor: #eb4634;
$elephantColor: #34b1eb;
$notificationBG: #fa0;
$cellSize: 60px;

body {
	font: 14px "Century Gothic", Futura, sans-serif;
	margin: 0;
	padding: 0;
}

.statusBlock {
	display: block;
	width: 100%;
	border-radius: $cellSize/3;
	padding: 5px 0px;
	margin-left: 60px;
	text-align: center;
	border: 1px solid #7a170c;
}
.statusBlock.rhino {
	background-color: $rhinoColor;
}
.statusBlock.elephant{
	background-color: $elephantColor;
}
.statusBlock.winner{
	background-color: $notificationBG;
}
.status {
  display: inline;
  font-weight: bold;
  font-size: 1.3rem;
}

.square {
	background: #fff;
	border: 2px solid #999;
	font-size: $cellSize/3*2;
	font-weight: bold;
	line-height: $cellSize/4*3;
	width: $cellSize;
	height: $cellSize;
	padding: 0;
	text-align: center;
	position: relative;
	flex-grow: 1;
}
.squareBorder.vertical {
	background: #ccc;
	border: 2px solid #999;
	height: $cellSize;
	padding: 0;
	width: $cellSize/3;
	position: relative;
	flex-grow: 1;
}
.squareBorder.horizontal {
	background: #ccc;
	border: 2px solid #999;
	height: $cellSize/3;
	padding: 0;
	width: $cellSize;
	position: relative;
	flex-grow: 1;
}
.squareCorner {
	height: $cellSize/3;
	padding: 0;
	width: $cellSize/3;
	position: relative;
	flex-grow: 1;
}

.focusOverlay{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #666;
	z-index: 500;
	display: none;
	opacity: 1;
}
@keyframes fadeOut {
	from {opacity: 0;}
	to {opacity: 0.5;}
}

.darken {
	opacity: 0.5;
	display: block;
	animation-name: fadeOut;
	animation-duration: 0.5s;
	animation-fill-mode: both;
}
.board {
	width: 5*$cellSize + 2*$cellSize/3;
	height: 5*$cellSize + 2*$cellSize/3;
	display: flex;
	flex-direction: column;
	
	margin-left: auto;
	margin-right: auto;
}
.board-row {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
}
.game-board {
	background:#999;
	border-radius: $cellSize/3;
	border:1px solid #000;
	padding:2px;
}
.game {
	padding: $cellSize/3;
	display: flex;
	flex-direction: row;
}
.userActionPanel {
	padding-top: 10px;
	padding-bottom: 15px;
}

.game-info {
  margin-left: $cellSize/3;
  display:flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
@keyframes zoomRotate {
	from {transform: scale(1);}
	to {transform: scale(1.2) rotate(360deg);}
}
.historyButtons{
	display: inline-block;
	border-radius: 8px;
	border:1px solid #000;
	background: #ddd;
	line-height:30px;
	margin: 0 10px 10px 0;
	padding: 5px;
	text-align: center;
	vertical-align: middle;
	font-size:20px;
	&.highlight{
		background: #666;
		color:#fff;
		border-color: #fff;
		animation-name: zoomRotate;
		animation-duration: .35s;
		animation-fill-mode: both;
	}
}

.square.mountain {
	background: #ccc;
}
.square.rhino {
	background: $rhinoColor;
}
.square.elephant {
	background: $elephantColor;
	color:#fff;
}
.square .fixedRotation.elephant::after{
	content: "\1f418";
	position: absolute;
	bottom:0;
	left:0;
	width: $cellSize/2;
	height: $cellSize/2;
	line-height: $cellSize/2;
	font-size: $cellSize/3;
	transform: scale(-1,1 );
}
.square .fixedRotation.rhino::after{
	content: "\1F98F";
	position: absolute;
	bottom:0;
	right:0;
	width: $cellSize/2;
	height: $cellSize/2;
	line-height: $cellSize/2;
	font-size: $cellSize/3;
}
.square .empty {
	background: #fff;
}
.square .mountain {
	background: #ccc;
}
.north {
	transform: rotate(0deg);
}
.south {
	transform: rotate(180deg);
}
.west {
	transform: rotate(270deg);
}
.east {
	transform: rotate(90deg);
}
button.active {
	background: #2b8a2b;
	color: #fff;
	transition: transform .35s ease-in-out;
}
button.active:hover {
	z-index: 100;
	/*transform: scale(1.2);*/
}

button.inactive {
	background: #eee;
	color: #888;
	border-color: #888;
}
button span {
	margin: 0;
	padding: 0;
}
button {
	padding: 0;
}
@keyframes zoom {
	from {transform: scale(1);}
	to {transform: scale(1.2);}
}
.selected {
	outline: $cellSize/12 dotted #000;
	z-index: 600;
	border-color: #000;
	animation-name: zoom;
	animation-duration: .35s;
	animation-fill-mode: both;
}
.square button:focus {
	outline: none;
}


.actionButtons{
	width: $cellSize/1.5;
	height: $cellSize/1.5;
	border-radius: 8px;
	border:1px solid #000;
	background: #ddd;
	line-height:$cellSize/2;
	margin-right: 10px;
	text-align: center;
	vertical-align: middle;
	font-size:$cellSize/2;
}

.selectedActions{
	background-Color: transparent;
	position: absolute;
	
	width: $cellSize*3.9;
	height: $cellSize*3.9;
	z-index: 1000;
}
.popupButton{
	width: $cellSize;
	height: $cellSize;
	border-radius: 8px;
	border:1px solid #000;
	background: #ddd;
	line-height:$cellSize/3*2;
	margin-left:$cellSize/12;
	margin-right: $cellSize/6;
	margin-top: $cellSize/12;
	margin-bottom: $cellSize/6;
	text-align: center;
	vertical-align: middle;
	font-size:$cellSize/3*2;
}
.popupButton.movement:after{
	content: "\1f3c3";
	font-size: $cellSize/3;
	position: relative;
	bottom: 37px;
	left: 15px;
}
.popupButton.rotation:after{
	content: "\21ba";
	font-size: $cellSize/2;
	line-height: $cellSize/3;
	bottom: 36px;
	position: relative;
	left: 15px;
}
.popupButton.insertPush:after{
	content: "\1f3c3*";
	font-size: $cellSize/2;
	line-height: $cellSize/3;
	bottom: 36px;
	position: relative;
	left: -5px;
}
.placeHolder{
	display:inline-block;
}
.hiddenPlaceHolder{
	opacity: 0;
	display:inline-block;
}
.popupButton.invisible{
	opacity: 0.5;
}
.animalsLeft .actionButtons{
	position: relative;
}
.animalsLeft {
	position: relative;
}
.animalsLeft .elephant{
	transform: scale(-1, 1);
	display: inline-block;
}
.animalsLeft .leftCounter{
	position: absolute; 
	top: 1px;
	right: 1px;
	font-weight: bold;
	z-index: 200;
	color: #fff;
	text-shadow: 0 0 4px #666;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.hide {
	display: none; 
}

.game.rhino {
	background: $rhinoColor;
}
.game.elephant {
	background: $elephantColor;
}
.header {
	display: flex;
}

