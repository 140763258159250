
$notificationBG2: #fa0;

.notification {
	display: flex;
	align-content: stretch;
	background: $notificationBG2;
	border: 1px solid #000;
	border-radius: 5px;
	box-shadow: 10px 10px 40px #333;
	padding: 30px; 
	line-height: 30px;
	font-size: 20px;
	font-weight: bolder;
	position: absolute;
	z-index: 6000;
	top: 90px;
	width: 300px;
	left: calc(40vw - (/* width */ 300px / 2)); 
	
	.messageText {
		flex-grow: 100;
		padding: 0 0 0 20px;
	}

	@keyframes animatedWinnerIcon{
		0%		{	transform: rotate(0deg);}
		25%		{	transform: rotate(0deg);}
		50%		{	transform: rotate(360deg);}
		75%		{	transform: rotate(0deg);}
		100%	{	transform: rotate(0deg);}
	}

	.icon {
		animation: animatedWinnerIcon 5s 1;
		animation-iteration-count: infinite;
		flex-grow: 1;
		flex-shrink: 0;
		font-size: 30px;
		line-height: 30px;
		vertical-align: middle;
		display: inline;
		padding: 0;
		margin: auto;
	}

	button {
		display: block;
		border-radius: 8px;
		border:1px solid #000;
		background: #ddd;
		line-height:30px;
		margin: 0 10px 10px 0;
		padding: 5px;
		text-align: center;
		position: absolute;
		top: -20px;
		right: -30px;
		font-size:20px;
	}
}